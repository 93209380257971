import { BlockItem } from "@components/atoms/BlockItem"
import { BlockWrapper } from "@components/atoms/BlockWrapper";
import { Button } from "@components/atoms/Button";
import { Pill } from "@components/atoms/Pill";
import { Tick } from "@components/atoms/Tick";
import { IconArrowTopRight, IconCaret, IconPlay } from "@components/svg/Icons";
import { defineBlock, EditableText } from "eddev/blocks"
import { forwardRef, useEffect, useLayoutEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { doc, getDoc, serverTimestamp, setDoc } from "firebase/firestore";
import { db } from "@utils/firebaseConfig";
import { SiteState } from "@components/site/SiteState";
import { useSnapshot } from "valtio";
import Vimeo from "@vimeo/player";
import { AnimatedTextBlock } from "@components/atoms/AnimatedTextBlock";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Texture } from "@components/Texture";
import Confetti from "react-confetti";
import { useWindowSize } from "react-use";

export const meta: BlockMeta = {
  title: "Home Course",
}

export type LessonType = {
  number: number;
  title: string;
  description: string;
  length: string;
  thumb: string;
  clip: string;
  vimeoUrl: string;
}

const CourseVideo = ({ playing, setPlaying, saveLesson, nextLesson }: { playing: boolean; setPlaying: React.Dispatch<React.SetStateAction<boolean>>; saveLesson: () => Promise<void>; nextLesson: () => void }) => {
  const videoRef = useRef<HTMLDivElement>(null)
  const player = useRef<Vimeo | null>(null)
  const lastUrl = useRef<string | null>(null)
  const siteState = useSnapshot(SiteState)
  const lesson = siteState.lessons[siteState.currentLesson]

  useLayoutEffect(() => {
    if (!videoRef.current) return
    lastUrl.current = lesson.vimeoUrl
    player.current = new Vimeo(videoRef.current, {
      url: lesson.vimeoUrl,
      autoplay: false,
      loop: false,
      byline: false,
      // @ts-ignore
      colors: ["222222", "EBBE32", "EBBE32", "222222"],
      portrait: false,
      responsive: true,
      title: false,
      vimeo_logo: false,
      pip: false,
    })

    player.current.on("timeupdate", (stats) => {
      // after 15 seconds, marked as "viewed"
      if (stats.seconds >= 15) {
        // console.log(player.current?.getVideoUrl())
        saveLesson()
      }
    })

    player.current.on("ended", () => {
      nextLesson()
    })

    return () => {
      player.current?.off("timeupdate")
      player.current?.off("ended")
      player.current?.destroy()
    }
  }, [videoRef])

  const shouldVideoPlay = async () => {
    if (!player.current) return
    if (lastUrl.current !== lesson.vimeoUrl) {
      player.current.loadVideo(lesson.vimeoUrl).then(() => {
        if (playing) player.current?.play()
      })
      lastUrl.current = lesson.vimeoUrl
    } else {
      if (playing) player.current?.play()
    }
  }

  useLayoutEffect(() => {
    shouldVideoPlay()
  }, [lesson, playing, videoRef])

  const playVideo = () => {
    if (!player.current) return
    setPlaying(true)
  }

  return (
    <div className="md:w-[65vw] md:shrink-0 flex flex-col">
      <BlockItem className="h-[55vw] md:h-[36vw] md:shrink-0" innerClassName="my-0 h-full">
        <div className="flex justify-center items-center w-full h-full">
          <div ref={videoRef} className="absolute w-full h-full left-0 top-0" />
          {!playing && (
            <>
              <video src={lesson.clip} className="absolute w-full h-full left-0 top-0 object-cover block" autoPlay muted playsInline loop />
              <div className="w-full relative flex flex-col h-full justify-center items-center">
                <div onClick={() => playVideo()} className="absolute left-0 top-0 w-full h-full cursor-pointer pointer-events-auto" />
                <Button icon={<IconPlay style={{ width: "1.5em", height: "1.5em" }} />} className="w-fit relative hover:bg-orange-light" onClick={() => playVideo()}>
                  <EditableText
                    as="span"
                    className=""
                    store="video-watch"
                    inlineToolbar={false}
                    defaultValue="Watch"
                    placeholder="Watch"
                  />
                </Button>
              </div>
            </>
          )}
        </div>
      </BlockItem>
      <BlockItem className="bg-orange md:h-full">
        <div className="flex gap-4 items-center">
          <div className="w-[20vw] md:w-[8vw] flex-shrink-0">
            <img src={lesson.thumb} alt="Lesson thumbnail" className="w-full h-auto block rounded-sm" />
          </div>
          <div className="flex flex-col gap-2 justify-between">
            <div className="flex gap-2 items-center">
              <Pill className="bg-orange-light text-black">{`Lesson ${lesson.number}`}</Pill>
              <Pill className="bg-black text-white">{lesson.length}</Pill>
            </div>
            <p className="type-title-m">{lesson.title}</p>
          </div>
        </div>
        <p className="mt-5 type-body-m">{lesson.description}</p>
      </BlockItem>
    </div>
  )
}

const LessonThumb = ({ lesson, index, play }: { lesson: LessonType; index: number; play: () => void }) => {
  const [shown, setShown] = useState(false)
  const siteState = useSnapshot(SiteState)

  const onScroll = (trigger: ScrollTrigger) => {
    setShown(true)
  }

  return (
    <BlockWrapper ignoreToolbar triggerStart="top bottom-=15%" onTrigger={onScroll}>
      <button onClick={() => play()} className={twMerge("w-full cursor-pointer p-3 flex gap-4 items-start rounded-sm transition-all opacity-0 hover:bg-beige-dark", `delay-${index * 100}`, shown ? "opacity-100" : "", index === siteState.currentLesson ? "bg-beige-dark" : "bg-beige")}>
        <div className="w-[32vw] flex-shrink-0 md:w-[9.6vw]">
          <img src={lesson.thumb} alt="Lesson thumbnail" className="w-full h-auto block rounded-sm" />
        </div>
        <div className="flex flex-col gap-3 md:gap-2 justify-start flex-1">
          <div className="flex justify-between items-center">
            <div className="flex gap-2 items-center">
              <Pill className="bg-orange text-black">{`Lesson ${lesson.number}`}</Pill>
              <Pill className="bg-black text-white">{lesson.length}</Pill>
            </div>
            <div className="type-title-m">
              <Tick active={siteState.lessonsWatched.includes(index + 1)} />
            </div>
          </div>
          <p className="type-label-l text-left">{lesson.title}</p>
          <p className="block type-body-xs text-left md:pr-7 md:whitespace-pre-line">{lesson.description}</p>
        </div>
      </button>
    </BlockWrapper>
  )
}

const CompleteTheCourse = ({ setPlaying }: { setPlaying: React.Dispatch<React.SetStateAction<boolean>>; }) => {
  const [expanded, setExpanded] = useState(true)
  const siteState = useSnapshot(SiteState)

  const play = (index: number) => {
    SiteState.currentLesson = index
    setPlaying(true)
  }

  return (
    <BlockItem className="bg-beige md:mr-1">
      <div className="flex flex-col items-center">
        <div className="flex justify-between items-center w-full pb-4 mb-3 border-b border-black type-title-m md:pb-6 md:mb-5">
          <div className="flex gap-4 items-center md:pr-3">
            {/* <button onClick={() => setExpanded(!expanded)} className={twMerge("group cursor-pointer p-3 rounded-md bg-beige-dark flex justify-center items-center md:hidden", expanded ? "open" : "")}>
              <IconCaret className="w-6 h-6 transition-transform group-[.open]:-scale-y-100" />
            </button> */}
            <AnimatedTextBlock textKey="title" textClassName="md:text-[0.9em]" triggerStart="top bottom-=15%" triggerEnd="bottom top" placeholder="Your course progress" />
          </div>
          <div className="flex flex-row-reverse items-center justify-end">
            {[...Array(siteState.lessons.length)].map((_, index) => (
              <Tick key={index} active={siteState.lessonsWatched.includes(siteState.lessons.length - index)} className="-ml-2" />
            ))}
          </div>
        </div>
        <div className={twMerge("flex gap-3 w-full transition-all", expanded ? "block" : "hidden")}>
          {siteState.lessons.map((lesson, index) => (
            <LessonThumb key={index} index={index} lesson={lesson} play={() => play(index)} />
          ))}
        </div>
      </div>
    </BlockItem>
  )
}

const Radio = ({ name, label, value }: { name: string; label: string; value: number; }) => {
  return (
    <div className="flex flex-col md:flex-row gap-2 items-center">
      <input className="shrink-0 w-5 h-5 md:w-4 md:h-4 p-0 m-0 appearance-none rounded-sm text-current border-none bg-white flex justify-center items-center before:w-[calc(var(--spacing-5)*0.58)] before:h-[calc(var(--spacing-5)*0.58)] md:before:w-[calc(var(--spacing-4)*0.58)] md:before:h-[calc(var(--spacing-4)*0.58)] before:rounded-[calc(var(--border-radius-sm)*0.5)] before:bg-orange before:hidden checked:before:block cursor-pointer" type="radio" name={name} id={`${name}-${value}`} value={value} />
      <label htmlFor={`${name}-${value}`} className="type-label-s text-center md:text-left md:type-body-xs leading-none cursor-pointer font-normal whitespace-pre-line md:whitespace-normal">{label}</label>
    </div>
  )
}

const Completed = ({ open, setOpen }: { open: boolean; setOpen: React.Dispatch<React.SetStateAction<boolean>>; }) => {
  const formRef = useRef<HTMLFormElement>(null)
  const { width, height } = useWindowSize()

  const questions = [
    {
      label: "Was the content on this website helpful?",
      name: "helpful",
      radios: [
        { label: "Not Helpful", value: 1 },
        { label: "Somewhat Helpful", value: 2 },
        { label: "Helpful", value: 3 },
        { label: "Very Helpful", value: 4 },
        { label: "Extremely Helpful", value: 5 },
      ]
    },
    {
      label: "Do you feel like you know what to do if you or someone you know contracts herpes?",
      name: "informed",
      radios: [
        { label: "Not \nPrepared", value: 1 },
        { label: "Somewhat \nPrepared", value: 2 },
        { label: "Very \nPrepared", value: 3 },
      ]
    },
    {
      label: "Do you feel more comfortable talking about about herpes with others?",
      name: "comfortable",
      radios: [
        { label: "Yes", value: 1 },
        { label: "No", value: 2 },
      ]
    },
  ]

  const submit = async (ev: any) => {
    ev?.preventDefault?.()
    const helpful = formRef.current?.helpful.value
    const informed = formRef.current?.informed.value
    const comfortable = formRef.current?.comfortable.value

    if (helpful && informed && comfortable && SiteState.user?.uid) {
      // console.log('submitted', helpful, informed, comfortable)
      try {
        await setDoc(doc(db, "surveys", SiteState.user.uid), {
          helpful: parseInt(helpful),
          informed: parseInt(informed),
          comfortable: parseInt(comfortable),
          country: SiteState.region,
          createdAt: serverTimestamp(),
        })
      } catch (e) {
        // console.error(e)
      }
      setOpen(false)
    }
  }

  return (
    <div className={twMerge("fixed top-0 left-0 w-full h-full z-[300] overflow-hidden pointer-events-none")}>
      <div className={twMerge("absolute top-0 left-0 w-full h-full bg-black opacity-50 transition-opacity duration-500", open ? "opacity-50 pointer-events-auto" : "opacity-0 pointer-events-none")} />
      {open && (
        <div className="absolute top-0 left-0 w-full h-full pointer-events-none">
          <Confetti width={width} height={height} colors={['#fff200', '#d14241', '#7ddfbe', '#ebbe32']} />
        </div>
      )}
      <div className={twMerge("absolute left-0 bottom-0 w-full pb-2 md:pb-1 flex justify-center items-center transition-transform duration-500 z-[301]", open ? "translate-y-0 pointer-events-auto" : "translate-y-full pointer-events-none")}>
        <BlockItem className="bg-beige">
          <div className="flex flex-col gap-5">
            <div className="flex justify-between items-center w-full">
              <Pill className="bg-orange">Congratulations</Pill>
              <Button className="pointer-events-auto bg-white hover:bg-beige-dark" onClick={() => setOpen(false)}>
                <svg style={{ width: "1em", height: "1em" }} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M4.46796 6.35356C4.66322 6.1583 4.66322 5.84172 4.46796 5.64645L0.76432 1.94282C0.569058 1.74755 0.569058 1.43097 0.76432 1.23571L1.23572 0.764305C1.43099 0.569043 1.74757 0.569043 1.94283 0.764305L5.64647 4.46794C5.84173 4.6632 6.15831 4.6632 6.35358 4.46794L10.0572 0.764305C10.2525 0.569043 10.5691 0.569042 10.7643 0.764305L11.2357 1.23571C11.431 1.43097 11.431 1.74755 11.2357 1.94282L7.53209 5.64645C7.33682 5.84172 7.33682 6.1583 7.53209 6.35356L11.2357 10.0572C11.431 10.2525 11.431 10.569 11.2357 10.7643L10.7643 11.2357C10.5691 11.431 10.2525 11.431 10.0572 11.2357L6.35358 7.53207C6.15831 7.33681 5.84173 7.33681 5.64647 7.53207L1.94283 11.2357C1.74757 11.431 1.43099 11.431 1.23572 11.2357L0.76432 10.7643C0.569058 10.569 0.569058 10.2525 0.76432 10.0572L4.46796 6.35356Z" fill="currentColor" />
                </svg>
              </Button>
            </div>
            <div className="flex gap-6 flex-col md:flex-row">
              <div className="flex flex-col gap-4">
                <p className="type-title-m md:type-title-l block">Outstanding. You’ve done your bit to help make New Zealand the best place in the world to have herpes.</p>
                <p className="type-body-xs md:type-body-l">Your grit and determination to finish this (fairly long course) has made a difference. How was it for you?</p>
              </div>

              <div className="md:w-1/2 shrink-0 type-body-m ">
                <form ref={formRef} className="flex flex-col gap-5" onSubmit={submit}>
                  {questions.map((question, index) => (
                    <div key={index} className="flex gap-5 items-start">
                      <p className="rounded-full flex justify-center items-center bg-black w-6 h-6 text-white type-label-s md:type-label-m shrink-0">{index + 1}</p>
                      <div className="flex flex-col gap-4">
                        <div className="min-h-6 flex items-center">
                          <p className="type-label-m md:type-label-l">{question.label}</p>
                        </div>
                        <div className="flex gap-4">
                          {question.radios.map((radio, index) => (
                            <Radio key={index} name={question.name} label={radio.label} value={radio.value} />
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}

                  <div className="-mt-8 md:mt-0 flex justify-end">
                    <Button className="md:hidden bg-orange" icon={<IconArrowTopRight style={{ width: "1.5em", height: "1.5em" }} />} onClick={() => submit(null)} reverse>
                      <EditableText
                        as="span"
                        className=""
                        store="completed-submit"
                        inlineToolbar={false}
                        defaultValue="Submit"
                        placeholder="Submit"
                      />
                    </Button>
                    <Button className="hidden md:flex bg-orange hover:bg-orange-light" icon={<IconArrowTopRight style={{ width: "1.5em", height: "1.5em" }} />} onClick={() => submit(null)} large reverse>
                      <EditableText
                        as="span"
                        className=""
                        store="completed-submit"
                        inlineToolbar={false}
                        defaultValue="Submit"
                        placeholder="Submit"
                      />
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <Texture />
        </BlockItem>
      </div>
    </div>
  )
}

export default defineBlock("home/course", (props) => {
  const [playing, setPlaying] = useState(false)
  const siteState = useSnapshot(SiteState)
  const dbWatched = useRef<number[]>([])
  const dbChecked = useRef(false)
  const [completed, setCompleted] = useState(false)

  const saveLesson = async () => {
    if (!SiteState.user?.uid || dbWatched.current.includes(SiteState.currentLesson + 1)) return
    try {
      // console.log('saving view', SiteState.currentLesson + 1, dbWatched.current.includes(SiteState.currentLesson + 1))
      dbWatched.current = [...new Set([...dbWatched.current, SiteState.currentLesson + 1])]
      SiteState.lessonsWatched = [...new Set(SiteState.lessonsWatched.concat([SiteState.currentLesson + 1]))]
      await setDoc(doc(db, "views", `${SiteState.user.uid}-${SiteState.currentLesson + 1}`), {
        country: SiteState.region,
        lesson: SiteState.currentLesson + 1,
        createdAt: serverTimestamp(),
      })
    } catch (e) {
      // console.error(e)
    }
  }

  const nextLesson = () => {
    if (SiteState.currentLesson + 1 < SiteState.lessons.length) {
      SiteState.currentLesson = SiteState.currentLesson + 1
      setPlaying(true)
    } else {
      setPlaying(false)

      // completed?
      if (SiteState.lessonsWatched.length === SiteState.lessons.length) {
        setCompleted(true)
      }
    }
  }

  // check if we've already watched the lessons
  useEffect(() => {
    (async () => {
      if (!siteState.user?.uid) return
      try {
        const activity = await getDoc(doc(db, "user_activity", siteState.user.uid))
        if (activity.exists()) {
          const data = activity.data()
          if (data?.views) {
            const views = [...new Set([...SiteState.lessonsWatched, ...data.views])]
            dbWatched.current = views
            SiteState.lessonsWatched = views
          }
        }
      } catch (e) {
        // console.error(e)
      }
      dbChecked.current = true
    })()
  }, [siteState.user])

  // // updated watched lessons as we go
  // useEffect(() => {
  //   const w = [...new Set(SiteState.lessonsWatched.concat([currentLesson + 1]))]
  //   SiteState.lessonsWatched = w
  // }, [currentLesson])

  // // save the lesson view we're watching
  // useEffect(() => {
  //   if (!dbChecked.current) return
  //   saveLesson()
  // }, [siteState.user, currentLesson])

  return (
    <>
      <BlockWrapper toolbarState="course-progress">
        <div id="course" className="flex flex-col md:flex-row">
          <CourseVideo playing={playing} setPlaying={setPlaying} saveLesson={saveLesson} nextLesson={nextLesson} />
          <CompleteTheCourse setPlaying={setPlaying} />
        </div>
      </BlockWrapper>
      <Completed open={completed} setOpen={setCompleted} />
    </>
  )
})